<template>
  <!-- 注册 -->
  <div>
    <el-dialog
      title="注册"
      :visible.sync="signShow"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      class="registered"
    >
      <div>
        <el-form
          ref="form"
          @submit.native.prevent
          :model="form"
          label-width="90px"
          :rules="rules"
        >
          <el-form-item label="委托单位" prop="clinic_name">
            <el-input
              v-model="form.clinic_name"
              size="small"
              type="text"
              placeholder="请输入委托单位"
              clearable
            />
          </el-form-item>
          <el-form-item label="委托人" prop="true_name">
            <el-input
              v-model="form.true_name"
              size="small"
              type="text"
              placeholder="请输入委托人"
            />
          </el-form-item>

          <el-form-item label="性别">
            <el-radio-group v-model="form.gender">
              <el-radio :label="2">男</el-radio>
              <el-radio :label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="省市区" required>
            <RegionSelect v-model="form.area"></RegionSelect>
          </el-form-item>
          <el-form-item label="详细地址" prop="addr">
            <el-input
              size="small"
              v-model="form.addr"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input
              size="small"
              v-model="form.mobile"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smscode" class="flex">
            <el-input
              v-model="form.smscode"
              size="small"
              type="text"
              style="width: 210px"
              placeholder="请输入验证码"
            />
            <el-button
              size="small"
              class="ml10"
              style="width: 160px; vertical-align: middle"
              :disabled="codeIndex != 60"
              @click="getNewMobileCode"
              >{{ codeName }}</el-button
            >
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              size="small"
              type="password"
              placeholder="请输入密码"
              autocomplete="new-password"
            />
          </el-form-item>

          <el-form-item label="确认密码" prop="password1">
            <el-input
              v-model="form.password1"
              size="small"
              type="password"
              placeholder="再次输入密码"
              autocomplete="new-password"
            />
          </el-form-item>
        </el-form>
      </div>
      <div
        style="
          display: flex;
          line-height: normal;
          background: #efefef;
          justify-content: center;
          height: 30px;
          align-items: center;
          margin-top: 20px;
        "
      >
        <el-checkbox v-model="loginAgreementVal"></el-checkbox>
        <el-link
          class="ml10"
          @click="dialogVisible = true"
          type="primary"
          style="font-size: 13px"
          >我已经阅读会员协议</el-link
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="changeMobile('form')"
          size="small"
          :loading="btnLoading"
          >注册</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="agreementVal.title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      :before-close="
        () => {
          dialogVisible = false;
        }
      "
    >
      <div class="agreement">
        <!-- <h3>
          {{ agreementVal.title }}
        </h3> -->
        <div class="ql-snow">
          <div
            class="con ql-editor"
            style="text-align: justify; word-break: break-word"
            v-html="content"
          ></div>
        </div>
        <div
          class="bottom"
          style="
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #efefef;
          "
        >
          <el-checkbox v-model="loginAgreementVal"
            >我已经阅读会员协议
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="mini"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
let time;
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import RegionSelect from "./regionSelect.vue";
export default {
  props: {
    signShow: Boolean,
  },
  components: { RegionSelect },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("输入的两个密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        clinic_name: "",
        true_name: "",
        gender: 2,
        area: {
          province: "",
          city: "",
          county: "",
        },
        addr: "",
        mobile: "",
        smscode: "",
        password: "",
        password1: "",
      },
      btnLoading: false,
      codeIndex: 60,
      codeName: "获取验证码",
      rules: {
        clinic_name: [
          {
            required: true,
            message: "请输入委托单位",
            trigger: "blur",
          },
        ],
        true_name: [
          {
            required: true,
            message: "请输入委托人",
            trigger: "blur",
          },
        ],
        addr: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
        ],
        smscode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 6,
            message: "验证码为6位数",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "长度为6-20个字符",
            trigger: "blur",
          },
        ],
        password1: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      loginAgreementVal: false,
      dialogVisible: false,
      agreementVal: {},
      content: "",
    };
  },
  created() {
    this.getAgreement();
  },
  methods: {
    getAgreement() {
      return this.$axios.post("/memberagreement").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          this.agreementVal = res.data;
          this.content = decodeURIComponent(res.data.content);
        }
      });
    },
    // 获取验证码
    getNewMobileCode() {
      let reg_mobile =
        /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/;
      if (!reg_mobile.test(this.form.mobile)) {
        this.$message.warning("请输入正确的手机号码");
        return;
      }

      if (this.codeIndex == 60) {
        this.codeIndex--;
        this.$axios
          .get("/registercode", {
            params: {
              mobile: this.form.mobile,
            },
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              time = setInterval(() => {
                this.codeName = "重新发送（" + this.codeIndex + "）";
                this.codeIndex--;
                if (this.codeIndex == 0) {
                  clearInterval(time);
                  this.codeName = "重新发送";
                  this.codeIndex = 60;
                }
              }, 1000);
            } else {
              this.$message.error(res.msg);
              this.codeIndex = 60;
            }
          })
          .catch(() => {
            this.$message.error("系统错误请稍后再试");
            this.codeIndex = 60;
          });
      }
    },
    //确认提交
    changeMobile(info) {
      this.$refs[info].validate((valid) => {
        if (valid) {
          if (!this.loginAgreementVal) {
            this.$message.warning("请先阅读会员协议");
          } else if (
            !this.form.area.province ||
            !this.form.area.city ||
            !this.form.area.county
          ) {
            this.$message.warning("请选择省市区");
            return false;
          } else {
            this.btnLoading = true;
            var params = {
              mobile: this.form.mobile,
              clinic_name: this.form.clinic_name,
              true_name: this.form.true_name,
              gender: this.form.gender,
              smscode: this.form.smscode,
              password: this.form.password,
              province: this.form.area.province,
              city: this.form.area.city,
              county: this.form.area.county,
              addr: this.form.addr,
            };
            this.$axios.post("/mobileregister", params).then((res) => {
              if (res.code != 1) {
                this.$message.error(res.msg);
              } else {
                this.codeIndex = 60;
                // this.$emit("handleClose");
                // this.$message.success(res.msg);
                this.$confirm(
                  "您已注册成功，登录账号为" + this.form.mobile,
                  "提示",
                  {
                    confirmButtonText: "确认",
                    type: "warning",
                  }
                )
                  .then(() => {
                    this.$emit("handleClose");
                    this.$refs[info].resetFields();
                  })
                  .catch(() => {
                    this.$emit("handleClose");
                    this.$refs[info].resetFields();
                  });
              }
              this.btnLoading = false;
            });
          }
        }
      });
    },
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>
<style lang="scss">
.registered {
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item__error {
    padding-top: 0;
  }
}
</style>