<template>
  <div id="loginpage">
    <div id="logincontent"></div>
    <div class="language">
      <!-- <el-button @click="languageClick" size="mini" type="primary">{{
        lang_data == 1 ? "中文" : "English"
      }}</el-button> -->
    </div>
    <div class="box">
      <ul>
        <li class="textCenter">
          <img src="@/assets/img/system.png" />
        </li>
        <li>
          <el-input
            v-model.trim="username"
            :maxlength="40"
            prefix-icon="el-icon-mobile-phone"
            type="text"
            size="samll"
            class="loginStyle"
            :placeholder="
              lang_data == 1 ? '请输入手机号或账号登录' : 'please enter account'
            "
            @on-focus="msgShow = false"
          />
        </li>
        <li class="mt20">
          <el-input
            v-model.trim="password"
            :maxlength="20"
            prefix-icon="el-icon-lock"
            type="password"
            size="samll"
            class="loginStyle"
            :placeholder="
              lang_data == 1 ? '请输入密码' : 'please enter password'
            "
            @on-focus="msgShow = false"
          />
          <span style="color: #f56c6c" v-if="msgShow">{{ msg }}</span>
        </li>
        <li class="opBtn">
          <el-checkbox v-model="remember">{{
            lang_data == 1 ? "记住密码" : "Remember password"
          }}</el-checkbox>
          <span style="float: right; color: #009a83; cursor: pointer">
            <router-link to="/forgot" tag="div">{{
              lang_data == 1 ? "忘记密码?" : "Forgot password?"
            }}</router-link>
          </span>
        </li>
        <li>
          <div
            style="
              display: flex;
              line-height: normal;
              align-items: end;
              margin-top: 30px;
            "
            class="mb10"
          >
            <el-checkbox v-model="loginAgreementVal"></el-checkbox>
            <el-link class="ml10" @click="dialogVisible = true">{{
              lang_data == 1
                ? "我已阅读会员协议"
                : "I have read the membership agreement"
            }}</el-link>
          </div>
          <el-button type="primary" style="width: 100%" @click="checkInput">
            {{ lang_data == 1 ? "登录" : "Sign In" }}
          </el-button>
        </li>
        <div style="margin: 10px 0; text-align: center">
          <el-link type="primary" @click="signClick">注册</el-link>
        </div>
      </ul>

      <div class="copyright">
        <div style="color: #999999; font-size: 12px" @click="clickLink">
          <div>粤ICP备2022010468号-1</div>
          <div>©2021 美鸣 粤ICP备2021060080号-1 All right reserved.</div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="agreementVal.title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="800px"
      :before-close="
        () => {
          dialogVisible = false;
        }
      "
    >
      <div class="agreement">
        <!-- <h3>
          {{ agreementVal.title }}
        </h3> -->
        <div class="ql-snow">
          <div class="con ql-editor" v-html="content"></div>
        </div>
        <div class="bottom">
          <el-checkbox v-model="loginAgreementVal">{{
            lang_data == 1
              ? "我已阅读会员协议"
              : "I have read the membership agreement"
          }}</el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="mini">{{
          lang_data == 1 ? "确认" : "Confirm"
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 注册 -->
    <Sign
      v-if="signShow"
      :signShow="signShow"
      @handleClose="signShow = false"
    ></Sign>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Sign from "./sign.vue";
export default {
  name: "login",
  mounted() {
    if (this.checkBrowser() == "Chrome") {
      this.massage = "";
    } else {
      this.massage = "建议使用谷歌（Chrome）浏览器访问！";
    }
    this.getData();
    this.getAgreement();
    localStorage.setItem("lang_data", this.lang_data);
  },
  components: { Sign },
  data() {
    return {
      remember: true,
      loginAgreementVal: true,
      username: "",
      password: "",
      msg: "",
      msgShow: false,
      massage: "",
      lang_data: localStorage.lang_data || 1,
      dialogVisible: false,
      agreementVal: {
        title: "会员协议",
        content: "",
      },
      content: "",
      signShow: false,
    };
  },
  methods: {
    getAgreement() {
      return this.$axios.post("/memberagreement").then((res) => {
        if (res.code != 1) {
          this.$message.error(res.msg);
          return;
        } else {
          if (res.data) {
            this.agreementVal = res.data;
            console.log(this.agreementVal);
            this.content = decodeURIComponent(res.data.content);
          }
        }
      });
    },
    clickLink() {
      window.location = "https://beian.miit.gov.cn";
    },
    languageClick() {
      if (this.lang_data == 0) {
        this.lang_data = 1;
      } else {
        this.lang_data = 0;
      }
      localStorage.setItem("lang_data", this.lang_data);
      this.getAgreement();
    },
    getData() {
      let remeber = localStorage.remember == "true" ? true : false;
      if (remeber) {
        if (localStorage.userInfo) {
          var userInfo = JSON.parse(localStorage.userInfo);
          this.username = userInfo.mobile;
          this.password = userInfo.password;
        }
      }
      this.remember = remeber;
    },
    remeberPwd() {
      //记住密码
      if (this.remember) {
        localStorage.userInfo = JSON.stringify({
          mobile: this.username,
          password: this.password,
        });
        localStorage.setItem("remember", this.remember);
      } else {
        localStorage.setItem("remember", false);
        localStorage.removeItem("userInfo");
      }
    },
    // 注册
    signClick() {
      this.signShow = true;
    },
    // 登录
    checkInput() {
      if (this.username && this.password) {
        if (!this.loginAgreementVal) {
          this.msgShow = true;
          this.msg = "请先阅读会员协议";
        } else {
          this.login();
        }
      } else {
        this.msg = "请输入手机号/账号和密码";
        this.msgShow = true;
      }
    },
    login() {
      this.$axios
        .post("login", {
          mobile: this.username,
          password: this.password,
          token: false,
          platform: 1, // 1 pc 2 公众号 3 app
          useragent: navigator.userAgent,
        })
        .then((res) => {
          if (res.code != 1) {
            this.msg = res.msg;
            this.msgShow = true;
          } else {
            // 处理记住密码
            this.remeberPwd(); // 记住密码逻辑
            localStorage.token = res.data.token;
            localStorage.is_admin = res.data.is_admin;
            let usercenter = {
              true_name: res.data.true_name,
              photo: res.data.photo,
              mobile: res.data.mobile,
              is_free_send: res.data.is_free_send,
            };
            localStorage.userData = JSON.stringify(res.data);

            this.changeUserInfo(usercenter);
            this.$router.push("/home");
          }
        });
    },
    checkBrowser() {
      var ua = navigator.userAgent.toLocaleLowerCase();
      var browserType = null;
      if (ua.match(/chrome/) != null) {
        browserType = "Chrome";
      } else {
        browserType = "Other";
      }
      return browserType;
    },
    ...mapActions(["changeUserInfo"]),
  },
};
</script>
<style lang="scss">
body {
  margin: 0 !important ;
  padding: 0;
}
</style>
<style lang="scss" scoped>
#loginpage {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  .language {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 2;
  }
  #logincontent {
    flex: 1.3;
    background: url("~@/assets/img/loginbg.png") no-repeat;
    background-size: cover;
    overflow: hidden;
  }
  .box {
    flex: 1;
    position: relative;
    background: #fff;
    display: flex;
    align-items: center;
    ul {
      width: 360px;
      padding: 0;
      margin: auto;
      padding-bottom: 100px;
      .textCenter {
        text-align: center;
        img {
          width: 280px;
        }
      }
      li {
        list-style: none;
        line-height: 35px;
        &:last-child {
          margin-top: 40px;
        }
      }
      img {
        width: 100px;
        height: auto;
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 30px;
    width: 100%;
    text-align: center;
  }
}
.agreement {
  h3 {
    text-align: center;
    margin-bottom: 10px;
  }
  .bottom {
    margin: 20px 0 10px;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    background: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom ::v-deep .el-checkbox__label {
    font-size: 16px;
    color: #333;
    margin-top: 1px;
  }
}
</style>
