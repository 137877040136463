<template>
  <div>
    <el-select
      v-model="province"
      :placeholder="provincePlaceholder"
      @change="onProvinceChange"
      :style="{ width: selectWidth }"
      clearable
      size="small"
    >
      <el-option
        v-for="item in provinceList"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      ></el-option>
    </el-select>
    <el-select
      v-model="city"
      :placeholder="cityPlaceholder"
      @change="onCityChange"
      :style="{ width: selectWidth }"
      clearable
      size="small"
    >
      <el-option
        v-for="item in cityList"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      ></el-option>
    </el-select>
    <el-select
      v-model="county"
      :placeholder="countyPlaceholder"
      @change="onCountyChange"
      :style="{ width: selectWidth }"
      clearable
      size="small"
    >
      <el-option
        v-for="item in countyList"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
export default {
  props: {
    // 包含省市区id的对象
    value: {
      type: Object,
      default: () => {
        return {
          province: "",
          city: "",
          county: "",
        };
      },
    },
    // 选择框宽度
    selectWidth: {
      type: [Number, String],
      default: "122px",
    },
    // 省占位符
    provincePlaceholder: {
      type: String,
      default: "省",
    },
    //市占位符
    cityPlaceholder: {
      type: String,
      default: "市",
    },
    //区占位符
    countyPlaceholder: {
      type: String,
      default: "区",
    },
  },
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      province: "",
      city: "",
      county: "",
      provinceList: [],
      cityList: [],
      countyList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 外部通过ref调用init初始化
    init() {
      let province = this.province;
      let city = this.city;
      let county = this.county;
      if (this.value) {
        // 初始化选中项
        province = this.value.province || "";
        city = this.value.city || "";
        county = this.value.county || "";
      }

      this.getProvinceList(true).then(() => {
        if (!province) return;
        this.getCityList(province, true).then(() => {
          if (!city) return;
          this.getCountyList(city, true).then(() => {
            this.province = province == 0 ? "" : province; //省
            this.city = city == 0 ? "" : city; //市
            this.county = county == 0 ? "" : county; //区县
          });
        });
      });
    },
    getRegion(id) {
      return new Promise((resolve) => {
        this.$axios
          .get("/regional", {
            params: {
              parent_id: id,
            },
          })
          .then(function (data) {
            if (data.code == "1") {
              resolve(data.data);
            } else {
              this.$message.error(data.msg);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    },
    // 初始化省
    getProvinceList(uninit) {
      if (!uninit) {
        this.city = "";
        this.county = "";
      }
      this.cityList = [];
      this.countyList = [];
      return this.getRegion(1).then((list) => {
        this.provinceList = list;
      });
    },
    //切换省 获取市 uninit 不初始化
    getCityList(id, uninit) {
      if (!uninit) {
        this.city = "";
        this.county = "";
      }
      this.countyList = [];
      return this.getRegion(id).then((list) => {
        this.cityList = list;
      });
    },
    // 切换市 获取区
    getCountyList(id, uninit) {
      if (!uninit) {
        this.county = "";
      }
      this.countyList = [];
      return this.getRegion(id).then((list) => {
        this.countyList = list;
      });
    },
    onProvinceChange(val) {
      this.getCityList(val).then(() => {
        this.emitChange();
      });
    },
    onCityChange(val) {
      this.getCountyList(val).then(() => {
        this.emitChange();
      });
    },
    onCountyChange() {
      this.emitChange();
    },
    emitChange() {
      let area = {
        province: this.province,
        city: this.city,
        county: this.county,
      };
      this.$emit("input", area);
      this.$emit("change", area);
    },
  },
};
</script>

